import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { HeaderComponent } from "./header/header.component";
import { LoadingComponent } from "./loading/loading.component";
import { NotificationGeneralComponent } from "./notification-general/notification-general.component";
import { ToolbarGeneralComponent } from "./toolbar-general/toolbar-general.component";

@NgModule({
    declarations: [
        HeaderComponent,
        ToolbarGeneralComponent,
        NotificationGeneralComponent,
        LoadingComponent
    ],
    exports:[
        HeaderComponent,
        ToolbarGeneralComponent,
        NotificationGeneralComponent,
        LoadingComponent,
        IonicModule
    ],
    imports: [
      CommonModule,
      IonicModule      
    ]
  })

export class ComponentsModule { 
}