import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { resetFakeAsyncZone } from '@angular/core/testing';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { LocalService } from './local.service';



@Injectable({
    providedIn: 'root'
})
export class SignalRServiceService {

    messageReceived$ = new Subject<any>();
    connectionEstablished$ = new BehaviorSubject<boolean>(false);

    public hubConnection: signalR.HubConnection;

    constructor(
        private configService : ConfigService,
        private localService : LocalService,
        private http: HttpClient) {
    }

    public createConnection() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withAutomaticReconnect()
            .withUrl(`${this.configService.getUrlNotification()}/notifications`, {
                skipNegotiation:false,
                withCredentials:false,
                transport: signalR.HttpTransportType.LongPolling
            })
            .build();
        this.registerOnServerEvents();
        this.startConnection();
    }

    private startConnection() {
        if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
            return;
        }

        this.hubConnection.start()
            .then(() => {
                console.log('Connection started!');
            })
            .then(() => this.getConnectionId())
            .catch(err => console.log('Error while establishing connection :(', err));
    }

    public getConnectionId = () => {
        this.hubConnection.invoke('getconnectionid').then(
           async (data) => {
                sessionStorage.setItem('connectionid',data);
                let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));
                let _userConnection = {
                    company:  dataAccess.companyID,
                    FiscalID: JSON.parse(sessionStorage.getItem(JSON.stringify('resaleId'))),
                    UserID : JSON.parse(sessionStorage.getItem(JSON.stringify('user'))),
                    Test : JSON.parse(sessionStorage.getItem('test')),
                    Token : dataAccess.token,
                    connectionID: data
                };

                // console.log(_userConnection);
                
                await this.configService.Post(`${this.configService.getUrlBase()}/UserConnection/CreateOrUpdate`, _userConnection)
                .catch((error: any) => {
                        console.log(error)
                });
            });          
    }

    private registerOnServerEvents(): void {
        this.hubConnection.on('SendMessage', (data: any) => {
            this.messageReceived$.next(data);
        });
        this.hubConnection.onclose((e) => {
            this.localService.logOut();
        });
        console.log('Trying to connect to hub!');
    }
}

