import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private http: HttpClient
  ) { }

  getUrlBase() {

    if (!environment.production) {
      return 'api';
      //return 'https://localhost:7156/api';

    }

    //return 'https://localhost:7156/api';
    return 'api';
  }

  getUrlNotification() {

    if (!environment.production) {
      return 'api';
    }

    //return 'https://localhost:7156/api';
    return 'api';
  }



  Get(apiURL): any {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ConnectionID': sessionStorage.getItem('connectionid') ?? ''
    });

    const promise = new Promise(async (resolve, reject) => {
      this.http
        .get<any[]>(apiURL, { headers: headers })
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        },
          err => {
            // Error
            reject(err);
          }
        );
    });
    return promise;
  }

  Post(apiURL, item): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ConnectionID': sessionStorage.getItem('connectionid') ?? ''
    });

    const promise = new Promise(async (resolve, reject) => {
      this.http
        .post<any[]>(apiURL, item, { headers: headers })
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        },
          err => {
            // Error
            reject(err.error);
          }
        );
    });
    return promise;
  }

  Put(apiURL, item): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ConnectionID': sessionStorage.getItem('connectionid') ?? ''
    });
    const promise = new Promise(async (resolve, reject) => {
      this.http
        .put<any[]>(apiURL, item, { headers: headers })
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        },
          err => {
            // Error
            reject(err);
          }
        );
    });
    return promise;
  }

  Delete(apiURL): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ConnectionID': sessionStorage.getItem('connectionid') ?? ''
    });
    const promise = new Promise(async (resolve, reject) => {
      this.http
        .delete<any[]>(apiURL, { headers: headers })
        .toPromise()
        .then((res: any) => {
          // Success
          resolve(res);
        },
          err => {
            // Error
            reject(err);
          }
        );
    });
    return promise;
  }

}
