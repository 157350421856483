import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from './services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  showHeader = true;

  textoUrl = '';

  constructor(
    private config: ConfigService,
    private platform: Platform,
    private lottieSPlashScreen: LottieSplashScreen,
    private statusBar: StatusBar,
    private domSanitizer: DomSanitizer,
    private route: Router
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.config.Get(`${this.config.getUrlBase()}/User/GetClientIdentity?company=${environment.resaleId}&test=${environment.production ? false : true}`)
      .then((result: any) => {

        let GetClientIdentity = JSON.parse(result.result);

        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', './theme/variables.scss');

        document.head.appendChild(linkElement);


        if (environment.production) {

          let picture = this.domSanitizer.bypassSecurityTrustHtml(GetClientIdentity.ImageProd);
          let databasePicture = 'data:image/png;base64,' + picture['changingThisBreaksApplicationSecurity'].toString();
          localStorage.setItem('logo', databasePicture);

          document.documentElement.style.setProperty('--ion-color-primary', GetClientIdentity.ColorPrimaryProd);
          document.documentElement.style.setProperty('--ion-color-primary-tint', GetClientIdentity.ColorPrimaryTintProd);
          document.documentElement.style.setProperty('--ion-color-background', GetClientIdentity.ColorBackgroundProd);
        } else {

          let picture = this.domSanitizer.bypassSecurityTrustHtml(GetClientIdentity.ImageTest);
          let databasePicture = 'data:image/png;base64,' + picture['changingThisBreaksApplicationSecurity'].toString();
          localStorage.setItem('logo', databasePicture);

          document.documentElement.style.setProperty('--ion-color-primary', GetClientIdentity.ColorPrimaryTest);
          document.documentElement.style.setProperty('--ion-color-primary-tint', GetClientIdentity.ColorPrimaryTintTest);
          document.documentElement.style.setProperty('--ion-color-background', GetClientIdentity.ColorBackgroundTest);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  initializeApp() {

    this.lottieSPlashScreen.show();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.lottieSPlashScreen.hide();
      }, 4000);
    });
  }
}
