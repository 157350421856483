import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(
    private router: Router
  ) { }


  get isLoggedIn(): boolean {
    return true;
  }

  public logOut() {
    sessionStorage.clear();
    this.router.navigateByUrl("/login");
  }
}
