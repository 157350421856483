import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { LocalService } from '../services/local.service';
import { NativeService } from '../services/native.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private nativeService: NativeService,
    private localService: LocalService,) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));
    if (dataAccess === null) {
      this.nativeService.OnlyAlert('Ups!!!', 'No tienes acceso', 'info').then(r => {
        this.localService.logOut();
      })
    }
    return true;
  }
}
