import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { LocalService } from 'src/app/services/local.service';
import { NotificationGeneralComponent } from '../notification-general/notification-general.component';

@Component({
  selector: 'app-toolbar-general',
  templateUrl: './toolbar-general.component.html',
  styleUrls: ['./toolbar-general.component.scss'],
})
export class ToolbarGeneralComponent implements OnInit {

  @Input() tittle : string='';
  @Input() backButton : boolean;
  @Input() menuButton : boolean = true;
  @Input() exit : boolean = true;
  @Input() loading : boolean = true;
  @Input() notificationButton : boolean;
  @Input() urlBack : string='';


  constructor(
    private modalController: ModalController,
    private localService : LocalService,
    private nav: NavController
  ) {
   }

  ngOnInit() {
  }


  onExit(){
    this.localService.logOut();
  }

  async openNotifications(){
    const modal = await this.modalController.create({
      component: NotificationGeneralComponent,
      componentProps: {
        User: '' 
      },
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();

  }

}
