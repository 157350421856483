import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "src/app/components/components.module";
import { DevExtremeModule } from "./dev-extreme/dev-extreme.module";
import { IonicSlides } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency'

import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectFade } from 'swiper';

import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';

import esMessages from 'devextreme/localization/messages/es.json';
import esCldrData from 'devextreme-cldr-data/es.json';
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json';

import Globalize from 'globalize';
import { AngularMaterialModule } from "./angular-material/angular.material.module";

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectFade, IonicSlides]);

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ".",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ",",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.NATURAL
  };

@NgModule({
    providers:[
    ],
    declarations:[
    ],
    imports:[
        HttpClientModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        DevExtremeModule,
        ComponentsModule,
        SwiperModule,
        NgbModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ],
    exports:[
        AngularMaterialModule,
        DevExtremeModule,
        ReactiveFormsModule,
        ComponentsModule,
        SwiperModule,
        NgbModule,
        NgxCurrencyModule
    ]
})

export class SharedModule { 
    constructor() {
        this.initGlobalize();
        Globalize.locale('es');
      }
    
      initGlobalize() {
        Globalize.load(
            esCldrData,
            supplementalCldrData
        );
        Globalize.loadMessages(esMessages);
    }
}