import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { PlantsPage } from 'src/app/components/popup/plants/plants.page';
import { LocalService } from 'src/app/services/local.service';
import { NativeService } from 'src/app/services/native.service';
import { SignalRServiceService } from 'src/app/services/signal.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

  loading = false;
  user = '';
  siteName = '';

  public appPages = [];

  public connectedSignalR = false;
  signalrConnectionEstablished$: Observable<boolean>;

  logo = localStorage.getItem('logo');

  breadcrumbList: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private signalRService: SignalRServiceService,
    private localService: LocalService,
    private nativeService: NativeService,
    private modalController: ModalController,
    private router: Router,
  ) {
    this.signalRService.createConnection();
  }

  ngOnInit() {

    this.signalrConnectionEstablished$ =
      this.signalRService.connectionEstablished$;
    this.signalRService.messageReceived$.subscribe((data: any) => {

      if (data !== null) {
        if (data.Type === 'RenewToken') {
          console.log('RenewToken');
          let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));
          dataAccess.token = data.result;
          sessionStorage.setItem('dataAccess', JSON.stringify(dataAccess));
        }

        if (data.Type === 'CloseConnection') {
          this.signalRService.hubConnection.onclose((e) => {
            console.log(e);
          });
          this.localService.logOut();
        }

        if (data.Type === 'CloseOtherConnection') {
          this.signalRService.hubConnection.onclose((e) => {
            console.log(e);
          });
          this.nativeService.presentToast('Iniciaste sesión en otro dispositivo');
          this.localService.logOut();
        }
      }
    });

    let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));
    this.siteName = dataAccess.siteName;

    let UserMenuPermissions = dataAccess.User.UserMenuPermissions;

    UserMenuPermissions.forEach(UserMenu => {
      let activated = false;

      if (this.route["_routerState"].snapshot.url.includes(`${UserMenu.Menu.url}`))
        activated = true;

      this.appPages.push({
        title: UserMenu.Menu.title,
        url: UserMenu.Menu.url,
        icon: UserMenu.Menu.icon,
        visible: UserMenu.Permission.Visible,
        enabled: UserMenu.Permission.Enabled,
        optional: UserMenu.Permission.Optional,
        active: activated
      });
    });
  }

  async PlantPopUp() {

    const modal = await this.modalController.create({
      component: PlantsPage,
      cssClass: 'general_modal',
      componentProps: {
      },
    });
    await modal.present();
    this.loading = true;
    const { data } = await modal.onDidDismiss();

    if (data !== undefined) {
      let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));
      dataAccess.siteID = data.Plant;
      dataAccess.siteName = data.Name;
      this.siteName = data.Name;
      sessionStorage.setItem('dataAccess', JSON.stringify(dataAccess));
    }
    this.loading = false;
  }

  activeLink(p) {

    if (p.optional) {
      this.nativeService.OnlyAlert('Alerta', 'No tienes acceso a este menú', 'info');
      return;
    }

    this.router.navigateByUrl(`home/${p.url}`);

    let allElements = document.querySelectorAll('.item_apps');
    allElements.forEach((element) => {
      element.classList.remove('active');
    });
    document.getElementById(p.url).classList.add('active');
  }

}
