import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() animation: boolean;
  @Input() positionTop: boolean;

  propertyBackground = 'transparent';

  constructor() {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', './theme/variables.scss');
    document.head.appendChild(linkElement);
    this.propertyBackground = document.documentElement.style.getPropertyValue('--ion-color-background');
    if (this.propertyBackground === '') this.propertyBackground = 'transparent';
    console.log(this.propertyBackground);
  }

  ngOnInit() { }

}
