import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard'
import { AdminComponent } from 'src/theme/Pages/admin/admin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/loginscreen/loginscreen.module').then(m => m.LoginscreenPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'home',
    component: AdminComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'orderstatus',
    loadChildren: () => import('./pages/order-status/order-status.module').then(m => m.OrderStatusPageModule)
  },
  {
    path: 'custshipstatus',
    loadChildren: () => import('./pages/cust-ship-status/cust-ship-status.module').then(m => m.CustShipStatusPageModule)
  },
  {
    path: 'cust-ship-pending',
    loadChildren: () => import('./pages/cust-ship-pending/cust-ship-pending.module').then(m => m.CustShipPendingPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
