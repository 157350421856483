import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notification-general',
  templateUrl: './notification-general.component.html',
  styleUrls: ['./notification-general.component.scss'],
})
export class NotificationGeneralComponent implements OnInit {

  constructor(
    public modalController: ModalController,
  ) { }

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  acceptChange(customer){
    this.modalController.dismiss(customer);
  }
}
