import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config.service';
import { LocalService } from 'src/app/services/local.service';
import { NativeService } from 'src/app/services/native.service';

@Component({
  selector: 'app-plants',
  templateUrl: './plants.page.html',
  styleUrls: ['./plants.page.scss'],
})
export class PlantsPage implements OnInit {

  company: any;
  user: any;
  site: any;
  token: any;

  loading = false;
  isOpen = false;
  showTittle = true;
  showTest = false;

  table: any;

  pageSize: number = 10;
  showPager: boolean = true;
  allowedPageSizes = [10];
  showPageSizeSelector: boolean = true;
  showNavButtons: boolean = true;

  allMode = 'allPages';
  checkBoxesMode = 'onClick';

  constructor(
    private config: ConfigService,
    private nativeService: NativeService,
    private route: ActivatedRoute,
    private router: Router,
    private localService: LocalService,
    private modalController: ModalController,
  ) { }


  async ionViewDidEnter() {
    let dataAccess = JSON.parse(sessionStorage.getItem('dataAccess'));

    if (dataAccess === null)
      this.showTittle = false;

    this.route.queryParams
      .subscribe(params => {
        this.showTest = params.test === undefined ? false : Boolean(params.test);
        sessionStorage.removeItem(JSON.stringify('showTest'));
        sessionStorage.setItem(JSON.stringify('showTest'), JSON.stringify(this.showTest))
        this.company = params.company === undefined ? dataAccess.companyID : params.company;
        sessionStorage.removeItem(JSON.stringify('company'));
        sessionStorage.setItem(JSON.stringify('company'), JSON.stringify(this.company))
        this.token = params.token === undefined ? dataAccess.token : params.token;
        sessionStorage.removeItem(JSON.stringify('token'));
        sessionStorage.setItem(JSON.stringify('token'), JSON.stringify(this.token))
        this.user = params.user === undefined ? JSON.parse(sessionStorage.getItem(JSON.stringify('user'))) : params.user;
        sessionStorage.removeItem(JSON.stringify('user'));
        sessionStorage.setItem(JSON.stringify('user'), JSON.stringify(this.user))
        this.site = params.site === undefined ? dataAccess.siteID : params.site;
        sessionStorage.removeItem(JSON.stringify('site'));
        sessionStorage.setItem(JSON.stringify('site'), JSON.stringify(this.site))
      }
      );

    await this.getAllPlants();
  }

  async getAllPlants(event?) {

    this.loading = true;
    let searchDataPlant = {
      "company": this.company,
      "token": this.token,
      "site": '',
      "test": JSON.parse(sessionStorage.getItem('test'))
    };
    var result = await this.config.Post(`${this.config.getUrlBase()}/Plant/GetAllPlants`, searchDataPlant);
    if (result.success)
      this.table = JSON.parse(result.result).PlantList;
    else {
      await this.nativeService.OnlyAlert('Notificación', result.message, 'warning').then(r => {
        if (result.message === 'No esta autorizado') {
          this.modalController.dismiss(0);
          this.localService.logOut();
        }
      });
    }

    if (event !== undefined)
      event.target.complete();

    this.loading = false;
  }

  ngOnInit() {

  }


  acceptChange(cellData) {
    this.nativeService.AlertConfirm('Notificación', 'Seguro que desea cambiar de planta?').then(async (r: any) => {
      if (r.isConfirmed) {
        this.modalController.dismiss(cellData.data);
      } else
        this.modalController.dismiss(0);
    })
  }


}
