import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSelectModule } from '@angular/material/select'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  exports: [
    MatAutocompleteModule, MatFormFieldModule, MatTableModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatDatepickerModule, MatBadgeModule, MatButtonToggleModule, MatDialogModule, MatCheckboxModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule
  ],
  declarations: [],
  imports: [
    CommonModule,
    MatAutocompleteModule, MatFormFieldModule, MatTableModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatDatepickerModule, MatBadgeModule, MatButtonToggleModule, MatDialogModule, MatCheckboxModule, MatIconModule, MatProgressSpinnerModule, MatTabsModule
  ]
})
export class AngularMaterialModule { }